import "./App.css";
import input from "./input.json";
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from "react";
import { CountUp } from "use-count-up";

function App() {
  const [hpCompleted, completedMasterThesis] = [
    input.hp,
    input.completedMasterThesis,
  ];
  const [finishedCount, setFinishedCount] = useState(false);
  const [isPortraitMode, setIsPortraitMode] = useState(false);

  const hpLeft = function () {
    return Math.round((300 - 13.5 - hpCompleted) * 100) / 100;
  };
  const completedPercentage = function () {
    console.log(((300 - hpLeft()) / 300) * 100);
    return Math.round(((300 - hpLeft()) / 300) * 1000) / 10;
  };
  const coursesLeft = function () {
    const masterPoints = completedMasterThesis ? 0 : 30;
    return Math.ceil((hpLeft() - masterPoints) / 7.5);
  };

  const isMobile = function () {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const calculateOrientation = function (angle) {
    if (!isMobile()) {
      setIsPortraitMode(false);
    } else if (angle === 0) {
      setIsPortraitMode(true);
    } else {
      setIsPortraitMode(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setFinishedCount(true);
    }, 4000);
  });

  useEffect(() => {
    console.log(window.screen.orientation.angle);
    calculateOrientation(window.screen.orientation.angle);

    window.addEventListener("orientationchange", function (event) {
      calculateOrientation(event.target.screen.orientation.angle);
      console.log(
        "the orientation of the device is now " +
          event.target.screen.orientation.angle
      );
    });
  });

  const finalText = function () {
    return (
      <p>
        or <CountUp isCounting start={34} end={coursesLeft()} duration={7} />{" "}
        courses {!completedMasterThesis ? "and a Master Thesis" : ""}
      </p>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <CountUp isCounting start={300} end={hpLeft()} duration={5} /> HP left
          to go
        </h1>
        <ProgressBar
          completed={finishedCount ? completedPercentage() : 0}
          borderRadius={"10px"}
          bgcolor={"#16b869"}
          width={isPortraitMode ? "68vw" : "35vw"}
          height={"13vh"}
          labelSize={isPortraitMode ? "4vh" : "6vh"}
          margin={"8vh 0"}
          labelAlignment={"right"}
        />
        {finalText()}
      </header>
    </div>
  );
}
export default App;
